import * as Routes from 'domain/routes'
import { PageHeader } from 'molecules/header/page-header'
import * as React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { AddressForm } from '@igs-web/common-components/domain/forms/address-form'
import { StateCode } from '@igs-web/common-models/constants/states'
import { Address } from '@igs-web/common-models/models/address'
import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { FormWrapper } from '@igs-web/common-ui-components/_atoms/form-wrapper'
import { StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { Checkbox, DayPicker, Input, Radio } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { ContainerGrid, Grid1fr1fr } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { emailRegex } from '@igs-web/common-utilities/utilities/form-validation'
import { isPhoneNumber } from '@igs-web/common-utilities/utilities/phone-utilities'

const requiredMsg = 'Required'
const formPrefix = 'transfer-service'
const sunday = 0
const saturday = 6

const SectionContainer = styled.div`
    display: grid;
    grid-gap: ${Spacing.Large};
`
const StyledFormWrapper = styled(FormWrapper)`
    max-width: ${Breakpoint.Desktop};
`
const ButtonContainer = styled.div`
    display: grid;
    justify-content: space-between;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
`

export const GasTransferServicePage = () => {
    const formMethods = useForm<TransferServiceRequestFields>()
    const history = useHistory()
    const onSubmit = data => console.log(data)

    const {
        register,
        control,
        formState: { errors },
        watch,
    } = formMethods

    const isValidDate = (date: Date) => {
        const weekday = date.getDay()
        const today = new Date()
        return !(weekday === sunday || weekday === saturday || date < today) //sunday or saturday
    }

    return (
        <ContainerGrid maxWidth={Breakpoint.Desktop}>
            <PageHeader message="Transfer Service Request Form" />

            <FormProvider {...formMethods}>
                <StyledFormWrapper onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <div>
                        <StrongText>Customer Information:</StrongText>
                        <Grid1fr1fr>
                            <Input
                                {...register('customerName', { required: requiredMsg })}
                                dataTestId={`${formPrefix}-customer-name`}
                                label="Customer Name"
                                autoComplete="Name"
                                error={errors.customerName?.message}
                                hideHelperText={!errors.customerName}
                            />
                            <Input
                                {...register('phoneNumber', {
                                    required: requiredMsg,
                                    validate: { isValidPhone: v => isPhoneNumber(v!) },
                                })}
                                dataTestId={`${formPrefix}-phone-number`}
                                label="Phone Number"
                                autoComplete="tel"
                                type="tel"
                                maxLength={10}
                                error={errors.phoneNumber?.message}
                                hideHelperText={!errors.phoneNumber}
                            />

                            <Input
                                {...register('email', { required: requiredMsg, pattern: { value: emailRegex, message: 'Invalid Email' } })}
                                dataTestId={`${formPrefix}-email`}
                                label="Email"
                                type="email"
                                autoComplete="email"
                                maxLength={100}
                                error={errors.email?.message}
                                hideHelperText={!errors.email}
                            />
                            <Input
                                {...register('accountNumber', { required: requiredMsg })}
                                dataTestId={`${formPrefix}-account-number`}
                                label="Scana Energy Account Number"
                                error={errors.accountNumber?.message}
                                hideHelperText={!errors.accountNumber}
                            />
                        </Grid1fr1fr>
                    </div>
                    <div>
                        <StrongText>Current Service Address Information:</StrongText>
                        <SectionContainer>
                            <AddressForm
                                formKey="[currentServiceAddress]."
                                idPrefix={`${formPrefix}-current-service-address-`}
                                labelPrefix="Service "
                                disableZip={false}
                                readOnlyState={StateCode.GA}
                            />

                            <Controller
                                control={control}
                                name="requestedStopDate"
                                rules={{ required: true }}
                                render={({ field: { onChange, value }, formState: { isValid } }) => (
                                    <DayPicker
                                        label="Requested Stop Date"
                                        dataTestId={`${formPrefix}-requestd-stop-date`}
                                        hideHelperText={isValid}
                                        onChange={onChange}
                                        value={value}
                                        datePickerProps={{ filterDate: isValidDate }}
                                    />
                                )}
                            />
                            <div>
                                <div>Please select a date for your service to begin. Technicians are not available on weekends or holidays.</div>
                                <Checkbox
                                    {...register('confirmStopDateTerms', { required: requiredMsg })}
                                    dataTestId={`${formPrefix}-confirm-stop-date-terms`}
                                    label="My Meter will be accessible."
                                    $error={!!errors.confirmStopDateTerms}
                                />
                            </div>
                        </SectionContainer>
                    </div>

                    <div>
                        <StrongText>Final Bill Information:</StrongText>
                        <Grid1fr1fr>
                            <Radio
                                {...register('finalBillMailToOption', { required: 'Required' })}
                                dataTestId={`${formPrefix}-${MailCommunicationOptions.CurrentServiceAddress}`}
                                label={MailCommunicationOptions.CurrentServiceAddress}
                                value={MailCommunicationOptions.CurrentServiceAddress}
                            />
                            <Radio
                                {...register('finalBillMailToOption', { required: 'Required' })}
                                dataTestId={`${formPrefix}-${MailCommunicationOptions.CurrentMailingAddress}`}
                                label={MailCommunicationOptions.CurrentMailingAddress}
                                value={MailCommunicationOptions.CurrentMailingAddress}
                            />
                            <Radio
                                {...register('finalBillMailToOption', { required: 'Required' })}
                                dataTestId={`${formPrefix}-${MailCommunicationOptions.NewServiceAddress}`}
                                label={MailCommunicationOptions.NewServiceAddress}
                                value={MailCommunicationOptions.NewServiceAddress}
                            />
                            <Radio
                                {...register('finalBillMailToOption', { required: 'Required' })}
                                dataTestId={`${formPrefix}-${MailCommunicationOptions.NewMailingAddress}`}
                                label={MailCommunicationOptions.NewMailingAddress}
                                value={MailCommunicationOptions.NewMailingAddress}
                            />
                        </Grid1fr1fr>
                        {watch('finalBillMailToOption') === MailCommunicationOptions.NewMailingAddress && (
                            <SectionContainer>
                                <AddressForm
                                    formKey="[finalBillDifferentMailingAddress]."
                                    idPrefix={`${formPrefix}-final-bill-mailing-address-`}
                                    labelPrefix="Mailing "
                                    disableZip={false}
                                />
                            </SectionContainer>
                        )}
                        <StrongText>New Service Address Information:</StrongText>
                        <SectionContainer>
                            <AddressForm
                                formKey="[newServiceAddressInformation]."
                                idPrefix={`${formPrefix}-new-service-address-`}
                                labelPrefix="New Service "
                                disableZip={false}
                                readOnlyState={StateCode.GA}
                            />
                        </SectionContainer>
                    </div>
                    <div>
                        <StrongText>Mailing Information:</StrongText>
                        <Grid1fr1fr>
                            <Radio
                                {...register('newMailCommunicationOption', { required: 'Required' })}
                                dataTestId={`${formPrefix}-${MailCommunicationOptions.NewServiceAddress}`}
                                label={MailCommunicationOptions.NewServiceAddress}
                                value={MailCommunicationOptions.NewServiceAddress}
                            />
                            <Radio
                                {...register('newMailCommunicationOption', { required: 'Required' })}
                                dataTestId={`${formPrefix}-${MailCommunicationOptions.NewMailingAddress}`}
                                label={MailCommunicationOptions.NewMailingAddress}
                                value={MailCommunicationOptions.NewMailingAddress}
                            />
                        </Grid1fr1fr>
                        {watch('newMailCommunicationOption') === MailCommunicationOptions.NewMailingAddress && (
                            <SectionContainer>
                                <AddressForm
                                    formKey="[newMailCommunicationMailingAddress]."
                                    idPrefix={`${formPrefix}-new-mailing-address-`}
                                    labelPrefix="Mailing "
                                    disableZip={false}
                                />
                            </SectionContainer>
                        )}
                    </div>
                    <div>
                        <StrongText>Start Date Information:</StrongText>
                        <div>Please select a date for your service to begin. Technicians are not available on weekends or holidays. </div>
                        <Controller
                            control={control}
                            name="requestedStartDate"
                            rules={{ required: true }}
                            render={({ field: { onChange, value }, formState: { isValid } }) => (
                                <DayPicker
                                    dataTestId="requested-start-date-selector"
                                    hideHelperText={isValid}
                                    onChange={onChange}
                                    value={value}
                                    datePickerProps={{ filterDate: isValidDate }}
                                    label="Requested Start Date"
                                />
                            )}
                        />
                        <div>
                            Atlanta Gas Light (AGL) may not be able to accommodate the date you selected. If your requested date is not available, we will
                            advise you of a different date. Please contact us if the new date is not convenient.
                        </div>
                        <div>
                            An AGL technician may need to enter this property to complete request. An adult (age 18 or older) must be present at the service
                            address.
                        </div>

                        <StrongText>Before the technician arrives:</StrongText>
                        <Checkbox
                            {...register('confirmStartDateTerms', { required: 'Required' })}
                            dataTestId="confirm-start-date-terms"
                            label="My water and electricity will be turned on, my meter and appliances will be accessible, and my pets will be secured."
                            $error={!!errors.confirmStartDateTerms}
                        />
                    </div>
                    <ButtonContainer>
                        <Button onClick={() => history.push(Routes.RateChange)}>Cancel</Button>
                        <Button type="submit">Submit</Button>
                    </ButtonContainer>
                </StyledFormWrapper>
            </FormProvider>
        </ContainerGrid>
    )
}

interface TransferServiceRequestFields {
    readonly customerName: string
    readonly phoneNumber: string
    readonly email: string
    readonly accountNumber: string
    readonly currentServiceAddress: Address
    readonly requestedStopDate: Date
    readonly confirmStopDateTerms: boolean
    readonly isMeterAccessible: boolean
    readonly finalBillMailToOption: MailCommunicationOptions
    readonly finalBillDifferentMailingAddress: Address
    readonly newServiceAddressInformation: Address
    readonly newMailCommunicationOption: MailCommunicationOptions
    readonly newMailCommunicationMailingAddress: Address
    readonly requestedStartDate: Date
    readonly confirmStartDateTerms: boolean
}

enum MailCommunicationOptions {
    CurrentServiceAddress = 'Use current service address',
    CurrentMailingAddress = 'Use current mailing address',
    NewServiceAddress = 'Use my new service address',
    NewMailingAddress = 'Use a different mailing address',
}
