/* eslint-disable functional/no-let */
import { pushConfirmationNumbersToGA, pushECommerceTrackingToGA } from '@igs-web/common-components/domain/analytics/enrollment-analytics'
import { SetConfirmationInfoRequest } from '@igs-web/common-components/domain/enrollment/confirmation/confirmation-redux'
import { Address } from '@igs-web/common-models/models/address'
import { ContactModel } from '@igs-web/common-models/models/contact-model'
import { EnrollmentModel, ShoppingCartItem } from '@igs-web/common-models/models/enrollment-model'
import { EnrollmentErrorCode, EnrollmentResponse } from '@igs-web/common-models/models/enrollment-response'
import { OfferModel, ProductModel } from '@igs-web/common-models/models/offer-model'
import { BankAccountFields, CreditCardFields, PaymentMethodModel, PaymentType, UtilityBillFields } from '@igs-web/common-models/models/payment-model'
import { publicWebsiteError } from '@igs-web/common-models/models/public-website-errors'
import {
    CreditCheckResponse,
    EnrollmentAddressRequest,
    EnrollmentContactRequest,
    EnrollmentPaymentRequest,
    EnrollmentProductRequest,
    EnrollmentRequest,
    EnrollmentShoppingCartItemRequest,
    enrollmentApiClient,
} from '@igs-web/common-utilities/api/enrollment-api-client'
import { mapAccountNumber } from '@igs-web/common-utilities/services/account-number-service'
import { getEventModel, pushEnrollmentSubmittedToGa } from '@igs-web/common-utilities/services/google-analytics-services'
import { FsEvent, fireCustomFullStoryEvent } from '@igs-web/common-utilities/utilities/fs-logger'
import { loadingManager } from '@igs-web/common-utilities/utilities/loading-manager-utilities'
import { removeNonNumeric } from '@igs-web/common-utilities/utilities/string-formatter'
import { generateUuid } from '@igs-web/common-utilities/utilities/uuid-utilities'

export const submitEnrollment = async (
    enrollmentModel: EnrollmentModel,
    selectedOffers: ReadonlyArray<Readonly<OfferModel>>,
    updateEnrollmentModel: (m: Partial<EnrollmentModel>) => void,
    setConfirmationInfo: (request: SetConfirmationInfoRequest) => void,
    clearConfirmationInfo: () => void,
    goToConfirmation: () => void,
) => {
    loadingManager.addLoader()
    let response: EnrollmentResponse | null = null
    if (enrollmentModel.shoppingCartItems.every(sci => !sci.successfullySubmitted)) {
        clearConfirmationInfo()
    }

    try {
        const request = mapToEnrollmentRequest(enrollmentModel, selectedOffers)
        response = await enrollmentApiClient.submitEnrollment(request)

        if (!response) {
            throw Error('Unable to Submit Sale')
        } else if (response.enrollmentErrorCode) {
            throw Error(response.enrollmentErrorCode)
        }

        pushEnrollmentSubmittedToGa(getEventModel(selectedOffers, response))

        updateEnrollmentModel({
            orderId: response.orderId,
            shoppingCartItems: enrollmentModel.shoppingCartItems.map(item => {
                const orderItem = response!.orderItems.find(
                    o => o.shoppingCartItemKey === item.shoppingCartItemKey && o.lineOfBusinessCode === item.lineOfBusinessCode,
                )

                if (!orderItem) {
                    return item
                }

                return {
                    ...item,
                    accountId: orderItem.accountId,
                    orderItemId: orderItem.orderItemId,
                    successfullySubmitted: orderItem.wasSuccessful,
                }
            }),
        })

        if (response.orderItems.some(oi => !oi.wasSuccessful)) {
            const lobs = response.orderItems
                .filter(oi => !oi.wasSuccessful)
                .map(oi => oi.lineOfBusinessCode)
                .join(', ')
            console.error(`${lobs} failed to submit`)
            if (response.errorMessage) {
                const errorIsForPayment = response.errorMessage.includes('Payment authorization issue')
                throw publicWebsiteError(errorIsForPayment ? 'An error occurred authorizing payment.' : response.errorMessage, errorIsForPayment ? true : false)
            }
        }
    } catch (error) {
        logKnownEnrollmentResponseError(error, enrollmentModel, selectedOffers)
        throw error
    } finally {
        loadingManager.removeLoader()

        if (response) {
            setConfirmationInfo({
                enrollmentModel,
                enrollmentResponse: response,
                offers: selectedOffers,
            })
        }
    }

    try {
        setTimeout(() => goToConfirmation(), 1000) // Just in case GA doesn't call hitCallback
        pushECommerceTrackingToGA(generateUuid(), enrollmentModel, [...selectedOffers])
        fireCustomFullStoryEvent(FsEvent.saleConfirmation, { enrollmentModel, offers: selectedOffers })
        await pushConfirmationNumbersToGA(response!)
    } catch (e) {
        console.error('There was a problem sending data to Google Analytics', e)
    } finally {
        goToConfirmation()
    }
}

const logKnownEnrollmentResponseError = (error: Error, enrollmentModel: EnrollmentModel, selectedOffers: ReadonlyArray<Readonly<OfferModel>>) => {
    if (error.message === EnrollmentErrorCode.matchingPartyFoundOfTypeOrganization) {
        fireCustomFullStoryEvent(FsEvent.matchingPartyFoundOfTypeOrganizationEnrollmentException, {
            enrollmentModel: JSON.stringify(enrollmentModel),
            offers: JSON.stringify(selectedOffers),
            errorCode: error.message,
            error: 'Matching party found of type Organization.  Cannot switch it to an Individual type this way without a supplied OrganizationName.',
        })
    } else if (error.message === EnrollmentErrorCode.matchingPartyFoundOfTypeIndividual) {
        fireCustomFullStoryEvent(FsEvent.matchingPartyFoundOfTypeIndividualEnrollmentException, {
            enrollmentModel: JSON.stringify(enrollmentModel),
            offers: JSON.stringify(selectedOffers),
            errorCode: error.message,
            error: 'Matching party found of type Individual.  Cannot switch it to an Organization type in this way with the supplied OrganizationName.',
        })
    } else if (error.message === EnrollmentErrorCode.accountIsBlocked) {
        fireCustomFullStoryEvent(FsEvent.accountIsBlockedEnrollmentException, {
            enrollmentModel: JSON.stringify(enrollmentModel),
            offers: JSON.stringify(selectedOffers),
            errorCode: error.message,
            error: 'Cannot perform sale. Account is blocked.',
        })
    } else if (error.message === EnrollmentErrorCode.accountIsObsolete) {
        fireCustomFullStoryEvent(FsEvent.accountIsObsoleteEnrollmentException, {
            enrollmentModel: JSON.stringify(enrollmentModel),
            offers: JSON.stringify(selectedOffers),
            errorCode: error.message,
            error: 'Cannot perform sale. Account is obsolete and no longer valid.',
        })
    } else if (error.message === EnrollmentErrorCode.matchingAccountForbidden) {
        fireCustomFullStoryEvent(FsEvent.matchedAccountEnrollmentException, {
            enrollmentModel: JSON.stringify(enrollmentModel),
            offers: JSON.stringify(selectedOffers),
            errorCode: error.message,
            error: 'Active, pending, or inactive account already exists with Account Number.',
        })
    } else {
        fireCustomFullStoryEvent(FsEvent.enrollmentException, {
            enrollmentModel,
            offers: selectedOffers,
            error: error,
        })
    }
}

const mapToEnrollmentRequest = (model: EnrollmentModel, offers: ReadonlyArray<OfferModel>): EnrollmentRequest => {
    return {
        ...model,
        campaignCode: offers[0].primaryProduct.campaignCode,
        invitationCode: offers[0].primaryProduct.invitationCode,
        accountType: offers[0].primaryProduct.accountType,

        orderId: model.orderId,
        moveInDate: model.moveInDate ? new Date(model.moveInDate) : null,
        defaultContact: mapToContactRequest(model.defaultContact),
        billingAddress: mapToAddressRequest(model.billingAddress, model),
        shippingAddress: mapToAddressRequest(model.shippingAddress, model),
        serviceAddress: mapToAddressRequest(model.serviceAddress, model),

        shoppingCartItems: model.shoppingCartItems
            .filter(o => !!o.offerId)
            .filter(o => !o.successfullySubmitted)
            .map(o => mapToShoppingCartItemRequest(o, offers, model.creditCheckResults)),
        autopayPayment: mapToPaymentRequest(model.autopayPayment),
        depositPayment: mapToPaymentRequest(model.depositPayment),
        isPaperless: model.isPaperless,
        hasElectronicCommunicationConsent: model.hasElectronicCommunicationConsent,
        realtorId: model.realtorId,
        realtorName: model.realtorName,
        electronicAgreementNumber: model.electronicAgreementNumber ? model.electronicAgreementNumber : null,
    }
}

const mapToAddressRequest = (address: Address | undefined, enrollmentModel: EnrollmentModel): EnrollmentAddressRequest => {
    return {
        address1: address && address.address1,
        address2: address && address.address2,
        city: address && address.city,
        state: address && address.state,
        zipCode: address && address.zipCode,
        hasCentralAc: !!enrollmentModel.hasCentralAc,
    }
}

const mapToShoppingCartItemRequest = (
    item: ShoppingCartItem,
    offers: ReadonlyArray<OfferModel>,
    creditCheckResults: CreditCheckResponse | undefined,
): EnrollmentShoppingCartItemRequest => {
    const offer = offers.find(o => o.offerId === item.offerId)

    return {
        offerId: item.offerId!,
        accountNumber: mapAccountNumber(item.accountNumber, offer?.primaryProduct.ulob?.utilityCode),
        accountId: item.accountId,
        partyId: creditCheckResults ? creditCheckResults.partyId : item.partyId,
        orderItemId: item.orderItemId,
        shoppingCartItemKey: item.shoppingCartItemKey,
        meterNumber: item.meterNumber,
        products: (() => {
            return offer
                ? [offer.primaryProduct, offer.secondaryProduct]
                      .map(mapToProductRequest)
                      .filter(o => o)
                      .map(o => o as EnrollmentProductRequest)
                : []
        })(),
        contact: mapToContactRequest(item.contact),
        payment: mapToPaymentRequest(item.payment),
        //BK - this is because item price effective date was getting set to a really weird date/time (01/01/0001 4:54 AM).
        // If we fix that bug, this can just become priceeffectivedate: item.priceeffectivedate
        // until that's fixed, let's make sure it's something reasonable, otherwise null it out
        priceEffectiveDate: item.priceEffectiveDate && item.priceEffectiveDate > new Date(2000, 0, 1) ? item.priceEffectiveDate : undefined,
        deferEnrollmentUnitilEndOfCurrentContractTerm: item.deferEnrollmentUnitilEndOfCurrentContractTerm,
    }
}

const mapToProductRequest = (product: ProductModel | undefined): EnrollmentProductRequest | undefined => {
    return product
        ? {
              lineOfBusinessCode: product.lineOfBusinessCode,
              productCode: product.productCode,
              utilityCode: product.ulob ? product.ulob.utilityCode : undefined,
              hpOfferCode: product.hpOfferCode,
              territoryCode: product.territoryCode,
              discountedPrice: product.discountedPrice,
              discountId: product.discountId,
              basePrice: product.price,
              unitOfMeasure: product.unitOfMeasure,
              ratePlanName: product.ratePlanName,
              monthlyCharge: product.monthlyCharge,
              overrideCampaignCode: product.overrideCampaignCode,
          }
        : undefined
}

const mapToContactRequest = (contact: ContactModel): EnrollmentContactRequest => {
    return {
        commercialName: contact.commercialName,
        emailAddress: contact.emailAddress,
        firstName: contact.firstName,
        lastName: contact.lastName,
        middleInitial: contact.middleInitial,
        phoneNumber: removeNonNumeric(contact.phoneNumber ?? ''),
        isMobilePhone: !!contact.isMobilePhone,
        isMobilePhoneMarketable: !!contact.isMobilePhoneMarketable,
        isEmailMarketable: !!contact.isEmailMarketable,
    }
}

const mapToPaymentRequest = (payment: PaymentMethodModel | null | undefined): EnrollmentPaymentRequest | undefined => {
    if (!payment) {
        return undefined
    }

    switch (payment.paymentType) {
        case PaymentType.Ach:
            const bankAccount = payment as BankAccountFields
            return {
                paymentType: payment.paymentType,
                name: bankAccount.name,
                bankAccountNumber: removeNonNumeric(bankAccount.bankAccountNumber ?? ''),
                bankRoutingNumber: removeNonNumeric(bankAccount.bankRoutingNumber ?? ''),
                zipCode: bankAccount.zipCode,
            }
        case PaymentType.CreditCard:
            const creditCard = payment as CreditCardFields
            return {
                paymentType: payment.paymentType,
                cardType: creditCard.cardType,
                name: creditCard.name,
                cardNumber: removeNonNumeric(creditCard.cardNumber ?? ''),
                expirationYear: creditCard.expirationYear,
                expirationMonth: creditCard.expirationMonth,
                securityCode: removeNonNumeric(creditCard.securityCode ?? ''),
                zipCode: removeNonNumeric(creditCard.zipCode ?? ''),
            }
        case PaymentType.UtilityBill:
            const billingUtility = payment as UtilityBillFields
            return {
                paymentType: payment.paymentType,
                billingUtilityCode: billingUtility.billingUtilityCode,
                billingLineOfBusinessCode: billingUtility.billingLineOfBusinessCode,
                billingUtilityAccountNumber: billingUtility.billingUtilityAccountNumber,
            }
        default:
            throw Error(`Unsupported PaymentType: '${payment.paymentType}'`)
    }
}
