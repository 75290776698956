import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { FaInfoCircle } from 'react-icons/fa'

import { get } from 'lodash-es'

import { FullStory } from '@igs-web/common-models/constants/full-story'
import { BankAccountFields } from '@igs-web/common-models/models/payment-model'
import { zipCodePattern } from '@igs-web/common-ui-components/_atoms/react-hooks-helpers'
import { ElementWithTooltip } from '@igs-web/common-ui-components/_atoms/tooltips/tool-tip'
import { Input } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { apiClient } from '@igs-web/common-utilities/api/api-client'
import { positiveIntegerRegex } from '@igs-web/common-utilities/utilities/form-validation'

export const AchCaptureForm = ({ formKey = '', idPrefix, showZipCode, payment, isReadonly, paymentSelectorField }: Props) => {
    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext()

    const nameField = `${formKey}name`
    const nameFieldErrors = get(errors, `${nameField}.message`)
    const bankAccountNumberField = `${formKey}bankAccountNumber`
    const bankAccountNumberFieldErrors = get(errors, `${bankAccountNumberField}.message`)
    const bankRoutingNumber = `${formKey}bankRoutingNumber`
    const bankRoutingNumberFieldErrors = get(errors, `${bankRoutingNumber}.message`)
    const zipCodeField = `${formKey}zipCode`
    const zipCodeFieldErrors = get(errors, `${zipCodeField}.message`)

    const paymentSelector = watch(paymentSelectorField)
    React.useEffect(() => {
        setValue(nameField, payment?.name)
        setValue(bankAccountNumberField, payment?.bankAccountNumber)
        setValue(bankRoutingNumber, payment?.bankRoutingNumber)
    }, [paymentSelector, watch])

    return (
        <React.Fragment>
            <Input
                {...register(nameField, { required: 'Required', shouldUnregister: true })}
                dataTestId={`${idPrefix}-ach-name`}
                defaultValue={payment?.name ?? ''}
                label="Name on Account"
                autoComplete="ach-name"
                error={nameFieldErrors}
                hideHelperText={!nameFieldErrors}
                readOnly={isReadonly}
            />
            <Input
                className={FullStory.Exclude}
                {...register(bankAccountNumberField, {
                    required: 'Required',
                    shouldUnregister: true,
                    pattern: { value: positiveIntegerRegex, message: 'Invalid Bank Account Number' },
                })}
                dataTestId={`${idPrefix}-ach-bank-account-number`}
                defaultValue={payment?.bankAccountNumber ?? ''}
                label="Bank Account Number"
                autoComplete="bank-account-number"
                error={bankAccountNumberFieldErrors}
                hideHelperText={!bankAccountNumberFieldErrors}
                readOnly={isReadonly}
            />
            <Input
                className={FullStory.Exclude}
                {...register(bankRoutingNumber, {
                    required: 'Required',
                    shouldUnregister: true,
                    pattern: { value: positiveIntegerRegex, message: 'Invalid Routing Number' },
                })}
                dataTestId={`${idPrefix}-ach-bank-routing-number`}
                defaultValue={payment?.bankRoutingNumber ?? ''}
                label="Routing Number"
                autoComplete="ach-bank-routing-number"
                error={bankRoutingNumberFieldErrors}
                hideHelperText={!bankRoutingNumberFieldErrors}
                readOnly={isReadonly}
            />
            {showZipCode && (
                <Input
                    {...register(zipCodeField, { required: 'Required', pattern: zipCodePattern, shouldUnregister: true })}
                    dataTestId={`${idPrefix}-ach-zip-code`}
                    defaultValue={payment?.zipCode ?? ''}
                    label="ZIP Code"
                    error={zipCodeFieldErrors}
                    hideHelperText={!zipCodeFieldErrors}
                    autoComplete="cc-postal-code"
                    maxLength={5}
                    readOnly={isReadonly}
                    inputMode="numeric"
                />
            )}

            <ElementWithTooltip
                defaultTooltipWidth={600}
                tooltipContents={
                    <div>
                        <img style={{ width: '100%' }} src={apiClient.getAssetPath('ach-guide.jpg')} />
                    </div>
                }
            >
                <span>
                    Help <FaInfoCircle />
                </span>
            </ElementWithTooltip>
        </React.Fragment>
    )
}

interface Props {
    readonly formKey?: string
    readonly idPrefix: string
    readonly payment?: BankAccountFields
    readonly isReadonly: boolean
    readonly paymentSelectorField: string
    readonly showZipCode?: boolean
}
