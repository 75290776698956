import styled from 'styled-components'

import { DynamicHeader } from '@igs-web/common-ui-components/_molecules/banners/dynamic-header'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

export const PageHeader = styled(DynamicHeader)`
    width: 100%;
    grid-column: 1 / -1;
    margin-bottom: ${Spacing.ExtraLarge};
    padding: ${Spacing.Large} ${Spacing.Small};
`
