import { SupportedCompanies } from '@igs-web/common-components/domain/company/company'
import { CommonWrapper } from '@igs-web/common-components/domain/render/common-wrapper'
import { persistor, store } from 'domain/redux'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { GlobalStyle } from './styles/app-styles'

const getCompanyFromDocument = (): string => {
    const company = document.documentElement.id.toLowerCase()
    const supportedCompany = Object.keys(SupportedCompanies).find(supported => supported.toLowerCase() === company)
    if (!supportedCompany) {
        throw Error(`Unsupported Company: '${company}'`)
    }
    return SupportedCompanies[supportedCompany]
}

ReactDOM.render(
    <React.StrictMode>
        <CommonWrapper companyName={getCompanyFromDocument()} store={store} persistor={persistor}>
            <BrowserRouter>
                <GlobalStyle />
                <App />
            </BrowserRouter>
        </CommonWrapper>
    </React.StrictMode>,
    document.getElementById('root'),
)
