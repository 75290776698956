import { get } from 'lodash-es'
import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { withHookFormMask } from 'use-mask-input'

import { Verbiage } from '@igs-web/common-components/domain/company/companyVerbiage'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { PhoneLink } from '@igs-web/common-components/scana-myaccount/scana-header'
import { FullStory } from '@igs-web/common-models/constants/full-story'
import { ContactModel } from '@igs-web/common-models/models/contact-model'
import { StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { Checkbox, Input } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { socialSecurityRegex } from '@igs-web/common-utilities/utilities/form-validation'
import { InputHelperText } from '@igs/react-styled-components'

import { DateInputForm } from './date-selector-form'

export const CreditCheckForm = ({ defaultContact, confirmedCreditCheckInfo = false }: Props) => {
    const {
        register,
        formState: { errors },
    } = useFormContext()

    const { enrollment: enrollmentFeatures, verbiage, phoneNumber } = useCompany()

    const confirmedCreditCheckInfoFieldError = get(errors, 'confirmedCreditCheckInfo.message')

    return (
        <>
            <Input
                {...register('firstName')}
                dataTestId="credit-check-first-name"
                defaultValue={defaultContact.firstName ?? ''}
                label="First Name"
                maxLength={50}
                readOnly={true}
                hideHelperText={true}
            />
            <Input
                {...register('lastName')}
                dataTestId="credit-check-last-name"
                defaultValue={defaultContact.lastName ?? ''}
                label="Last Name"
                maxLength={250}
                readOnly={true}
                hideHelperText={true}
            />
            <Input
                {...register('phoneNumber')}
                dataTestId="credit-check-phone-number"
                label="Phone Number"
                defaultValue={defaultContact.phoneNumber ?? ''}
                readOnly={true}
                maxLength={15}
                type="tel"
                hideHelperText={true}
            />
            <DateInputForm
                idPrefix="credit-check-date-of-birth"
                minValidationFailedMessage={
                    <>
                        Invalid date of birth. You must be at least 18 years old. Please call <PhoneLink phoneNumber={phoneNumber.customerSupport} /> with any
                        questions.
                    </>
                }
            />
            <Input
                {...withHookFormMask(
                    register('socialSecurityNumber', {
                        required: 'Required',
                        pattern: { value: socialSecurityRegex, message: 'Invalid social security number' },
                    }),
                    '999-99-9999',
                )}
                label="Social Security Number"
                className={FullStory.Exclude}
                dataTestId="credit-check-social"
                error={errors.socialSecurityNumber?.message}
                hideHelperText={!errors.socialSecurityNumber?.message}
            />
            {enrollmentFeatures.affirmCreditCheckInfo && (
                <div>
                    <StrongText>{verbiage[Verbiage.creditCheckVerificationMessage]}</StrongText>
                    <Checkbox
                        {...register('confirmedCreditCheckInfo', { required: 'Required' })}
                        defaultChecked={confirmedCreditCheckInfo}
                        dataTestId="credit-check-info"
                        label="The above information is correct."
                        $error={!!confirmedCreditCheckInfoFieldError}
                    />
                    <InputHelperText error={confirmedCreditCheckInfoFieldError} currentLength={0} />
                </div>
            )}
        </>
    )
}

interface Props {
    readonly defaultContact: ContactModel
    readonly confirmedCreditCheckInfo?: boolean
}

export interface CreditCheckFields {
    readonly firstName: string
    readonly lastName: string
    readonly phoneNumber: string
    readonly date: Date
    readonly socialSecurityNumber: string
    readonly confirmedCreditCheckInfo?: boolean
}
