import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { lightLight, primaryLinearGradient } from '@igs-web/common-ui-components/styles/theme'
import * as React from 'react'
import styled from 'styled-components'

const HeaderBackground = styled.div`
    background: ${primaryLinearGradient};
    text-align: center;
    padding: ${Spacing.Large};
    && {
        grid-column: 1/-1;
    }
`
const HeaderTitle = styled.h2`
    color: ${lightLight};
    font-weight: ${FontWeight.Default};
`

export const DynamicHeader = ({ title, message, className }: DynamicHeaderProps) => {
    return (
        <HeaderBackground className={className}>
            <HeaderTitle>
                <strong>{title} </strong>
                {message}
            </HeaderTitle>
        </HeaderBackground>
    )
}

interface DynamicHeaderProps {
    readonly message?: string
    readonly title?: string
    readonly className?: string
}
