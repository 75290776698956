import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { wideBanner } from '@igs-web/common-components/_molecules/full-width-banner'
import { CheckoutStepPageIds } from '@igs-web/common-components/domain/checkout/checkout-step-page-ids'
import { CheckoutProvider, useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { OfferSelectors, offersActions } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { ErrorBoundary } from '@igs-web/common-components/domain/errors/error-boundary'
import { useOffersContextDispatch } from '@igs-web/common-components/domain/offers/context/offers-context'
import { useBreakpoints } from '@igs-web/common-components/hooks/use-breakpoints'
import { EnrollmentModel } from '@igs-web/common-models/models/enrollment-model'
import { IconType } from '@igs-web/common-models/models/icon-type'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { ProgressTrack } from '@igs-web/common-ui-components/_molecules/progress-track/progress-track'
import { Wizard } from '@igs-web/common-ui-components/_molecules/wizard/wizard'
import { useWizardState } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { WizardPage, WizardPageSection } from '@igs-web/common-ui-components/_molecules/wizard/wizard-page'
import { WizardProgressTrackStep } from '@igs-web/common-ui-components/_molecules/wizard/wizard-progress-track'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { background } from '@igs-web/common-ui-components/styles/theme'
import { fetchIpAddress } from '@igs-web/common-utilities/api/ip-address-client'
import { CHECKOUT_STORAGE_KEY, GAS_CHECKOUT_WIZARD_MESSAGES_STORAGE_KEY } from '@igs-web/common-utilities/constants/constants'
import { getFromStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

import { NavigationBanner } from 'molecules/header/navigation-banner'

import { ServiceAddressSearchStep } from 'domain/checkout/gas-checkout/service-address-search/service-address-step'
import * as Routes from 'domain/routes'

import { AssistanceFooter } from '../assistance-footer/assistance-footer'
import { CheckoutWizardMessages } from '../checkout-wizard-messages/checkout-wizard-messages'
import { CheckoutWizardMessagesProvider, useCheckoutWizardMessagesDispatch } from '../checkout-wizard-messages/checkout-wizard-messages-context'
import { CheckoutWizardMessagesModel } from '../checkout-wizard-messages/checkout-wizard-messages-models'
import { useCheckoutWizardOfferMonitor } from '../hooks/use-checkout-wizard-offer-monitor'
import { useOffersHandler } from '../hooks/use-offers-handler'
import { useOffersQueryStringHandler } from '../hooks/use-offers-query-string-handler'
import { useQueryStringRealtorCheck } from '../hooks/use-query-string-realtor-check'
import { SelectRateStep } from '../rate/select-rate-step'
import { CheckoutGrid, CheckoutLayout } from '../shared/checkout-components'
import { YourPlanGas } from '../your-plan/your-plan-gas'
import { gasShowAddressCard, gasShowCreditCheckCard, gasShowMyDetailsCard, gasShowRateCard } from '../your-plan/your-plan-service'

import { ContactDetailsStep } from './contact-details/contact-details-step'
import { CreditCheckStep } from './credit-check-step'
import { OrderReviewStep } from './review/order-review-step'
import { StartDateStep } from './start-date/start-date-step'

const WizardPages = () => {
    const company = useCompany()
    const dispatch = useDispatch()

    const { clearOfferState } = useOffersContextDispatch()

    const enrollmentState = useCheckoutState()
    useQueryStringRealtorCheck()
    const selectedOffers = useSelector((store: CommonReduxState) => OfferSelectors.selectSelectedOffers(store))

    const { activePageId } = useWizardState()
    const { setShowSelectedOfferExpiredMessage } = useCheckoutWizardMessagesDispatch()

    const { isSelectedOfferExpired, checkIsSelectedOfferExpired } = useCheckoutWizardOfferMonitor()
    const { getGasOffersQueryStringParameters } = useOffersQueryStringHandler()
    const offersHandler = useOffersHandler()

    const { lessThanOrEqualTo } = useBreakpoints()
    const isMobile = lessThanOrEqualTo(Breakpoint.Tablet)

    React.useEffect(() => {
        if (selectedOffers.some(o => o.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty)) {
            dispatch(offersActions.unselectOffer(selectedOffers.find(o => o.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty)))
        }
    }, [selectedOffers])

    React.useEffect(() => {
        //-- TODO: Use OffersContext for enrollment
        clearOfferState()
        const queryStringParameters = getGasOffersQueryStringParameters()
        offersHandler.selectOfferIfAvailable(
            queryStringParameters.offerId,
            queryStringParameters.campaignCode,
            queryStringParameters.priceEffectiveDate,
            queryStringParameters.promoCode,
            company.enrollment.defaultZipCode,
        )
        fetchIpAddress()
    }, [])

    React.useEffect(() => {
        setShowAssistanceFooter(activePageId === CheckoutStepPageIds.CustomerAddress)
    }, [activePageId])

    React.useEffect(() => {
        setShowSelectedOfferExpiredMessage(isSelectedOfferExpired)
    }, [isSelectedOfferExpired])

    const showRateCard = gasShowRateCard(selectedOffers[0]?.primaryProduct)
    const showAddressCard = gasShowAddressCard(activePageId, enrollmentState.serviceAddress)
    const showMyDetailsCard = gasShowMyDetailsCard(enrollmentState.defaultContact.firstName)
    const showCredicCheckCard = gasShowCreditCheckCard(enrollmentState.creditCheckResults)

    const showYourPlan =
        !isMobile &&
        activePageId !== CheckoutStepPageIds.SelectRate &&
        activePageId !== CheckoutStepPageIds.OrderSummary &&
        (showRateCard || showAddressCard || showMyDetailsCard || showCredicCheckCard)

    const [showAssistanceFooter, setShowAssistanceFooter] = React.useState(false)

    return (
        <>
            <CheckoutWizardMessages />
            <CheckoutLayout $showSidebar={showYourPlan}>
                <WizardPageSection gridarea="content">
                    <WizardPage pageId={CheckoutStepPageIds.CustomerAddress}>
                        <ServiceAddressSearchStep setShowAssistanceFooter={setShowAssistanceFooter} checkIsSelectedOfferExpired={checkIsSelectedOfferExpired} />
                    </WizardPage>
                    <WizardPage pageId={CheckoutStepPageIds.SelectRate}>
                        <SelectRateStep />
                    </WizardPage>
                    <WizardPage pageId={CheckoutStepPageIds.ContactInfo}>
                        <ContactDetailsStep stepFwdButtonText="CONTINUE" checkIsSelectedOfferExpired={checkIsSelectedOfferExpired} />
                    </WizardPage>
                    <WizardPage pageId={CheckoutStepPageIds.ResidentialDeposit}>
                        <CreditCheckStep stepFwdButtonText="CONTINUE" checkIsSelectedOfferExpired={checkIsSelectedOfferExpired} />
                    </WizardPage>
                    <WizardPage pageId={CheckoutStepPageIds.RequestedStartDate}>
                        <StartDateStep stepFwdButtonText="CONTINUE" checkIsSelectedOfferExpired={checkIsSelectedOfferExpired} />
                    </WizardPage>
                    <WizardPage pageId={CheckoutStepPageIds.OrderSummary}>
                        <OrderReviewStep
                            confirmationUrl={Routes.ConfirmationRoute}
                            stepFwdButtonText="SUBMIT"
                            checkIsSelectedOfferExpired={checkIsSelectedOfferExpired}
                        />
                    </WizardPage>
                </WizardPageSection>
                {showYourPlan && <YourPlanGas />}
                {showAssistanceFooter && <AssistanceFooter />}
            </CheckoutLayout>
        </>
    )
}

export const Checkout = () => {
    const company = useCompany()

    const storedEnrollmentModel = getFromStorage<EnrollmentModel>(CHECKOUT_STORAGE_KEY, false)
    const storedCheckoutWizardMessagesModel = getFromStorage<CheckoutWizardMessagesModel>(GAS_CHECKOUT_WIZARD_MESSAGES_STORAGE_KEY, false)

    return (
        <ErrorBoundary company={company}>
            <CheckoutGrid maxWidth={Breakpoint.LargeDesktop}>
                <CheckoutProvider enrollmentState={storedEnrollmentModel} storageKey={CHECKOUT_STORAGE_KEY}>
                    <NavigationBanner title="Sign Up For Service" style={wideBanner} />

                    <Wizard>
                        <ProgressTrack bgColor={background}>
                            <WizardProgressTrackStep
                                pageId={CheckoutStepPageIds.CustomerAddress}
                                label="Address"
                                iconType={IconType.CircleServiceAddressIcon}
                            />
                            <WizardProgressTrackStep pageId={CheckoutStepPageIds.SelectRate} label="Rates" iconType={IconType.CircleTag} />
                            <WizardProgressTrackStep pageId={CheckoutStepPageIds.ContactInfo} label="My Details" iconType={IconType.CircleContactInfoIcon} />
                            <WizardProgressTrackStep
                                pageId={CheckoutStepPageIds.ResidentialDeposit}
                                label="Credit Check"
                                iconType={IconType.CircleCreditCheckIcon}
                            />
                            <WizardProgressTrackStep
                                pageId={CheckoutStepPageIds.RequestedStartDate}
                                label="Start Date"
                                iconType={IconType.CircleProductDetailsIcon}
                            />
                            <WizardProgressTrackStep pageId={CheckoutStepPageIds.OrderSummary} label="Review" iconType={IconType.CircleReviewIcon} />
                        </ProgressTrack>
                        <CheckoutWizardMessagesProvider
                            checkoutWizardMessagesState={storedCheckoutWizardMessagesModel}
                            storageKey={GAS_CHECKOUT_WIZARD_MESSAGES_STORAGE_KEY}
                        >
                            <WizardPages />
                        </CheckoutWizardMessagesProvider>
                        <GridSpacer spacing={Spacing.SectionGap} />
                    </Wizard>
                </CheckoutProvider>
            </CheckoutGrid>
        </ErrorBoundary>
    )
}
