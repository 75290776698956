import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { IoMdCalendar } from 'react-icons/io'
import { useSelector } from 'react-redux'

import styled, { css } from 'styled-components'

import { CheckoutStepProps } from '@igs-web/common-components/domain/checkout/checkout-models'
import { useCheckoutDispatch, useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { StepNav } from '@igs-web/common-components/domain/checkout/steps/step-nav'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { PhoneType } from '@igs-web/common-models/constants/phone'
import { EnrollmentTypeCode } from '@igs-web/common-models/models/enrollment-type-code'
import { ErrorField } from '@igs-web/common-ui-components/_atoms/form-inputs/error-field'
import { FormWrapper } from '@igs-web/common-ui-components/_atoms/form-wrapper'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { Radio } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { useWizardDispatch } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { getEventModel, pushStartDateEvent } from '@igs-web/common-utilities/services/google-analytics-services'

import { MarketSwitchEstimatedStartDate } from './market-switch'
import { NewServiceStartDate } from './start-date-selector'

const mobileStrategy = css`
    @media (max-width: ${Breakpoint.Tablet}) {
        max-width: ${Breakpoint.MobileExtraSmall};
        padding: ${Spacing.Small};
    }
`
const ServiceTypeInputContainer = styled.div`
    margin-left: ${Spacing.Small};
    ${mobileStrategy}
`
const StyledRadio = styled(Radio)`
    ~ p {
        margin: ${Spacing.Small};
    }
`

export const StartDateStep = ({ stepFwdButtonText, checkIsSelectedOfferExpired }: StartDateStepProps) => {
    const enrollmentState = useCheckoutState()
    const { setEnrollmentState } = useCheckoutDispatch()
    const selectedOffers = useSelector(OfferSelectors.selectSelectedOffers)
    const formMethods = useForm({
        defaultValues: {
            enrollmentTypeCode: enrollmentState.enrollmentTypeCode,
        },
    })
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = formMethods
    const { nextPage } = useWizardDispatch()
    const onSubmit = async ({ requestedStartDate, ...formData }) => {
        const startDate = typeof requestedStartDate === 'string' ? new Date(`${requestedStartDate}T12:00:00.000Z`) : requestedStartDate
        const isKeepMeInformed = enrollmentState.defaultContact.phoneType === PhoneType.Home ? false : formData.isEnrolledInKeepMeInformed
        setEnrollmentState({ ...enrollmentState, ...formData, requestedStartDate: startDate, isEnrolledInKeepMeInformed: isKeepMeInformed })
        const isSelectedOfferExpired = await checkIsSelectedOfferExpired()
        if (!isSelectedOfferExpired) {
            pushStartDateEvent(getEventModel(selectedOffers), nextPage)
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const enrollmentTypeCode = watch('enrollmentTypeCode')

    return (
        <>
            <FormProvider {...formMethods}>
                <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                    <IconHeading>
                        <IoMdCalendar /> Start Date
                    </IconHeading>
                    <ServiceTypeInputContainer>
                        <StyledRadio
                            {...register('enrollmentTypeCode', { required: 'Required' })}
                            dataTestId="business-service-type"
                            label="Sign Up for New Service"
                            defaultChecked={enrollmentState.enrollmentTypeCode === EnrollmentTypeCode.NewService}
                            value={EnrollmentTypeCode.NewService}
                        />
                        <StyledRadio
                            {...register('enrollmentTypeCode', { required: 'Required' })}
                            dataTestId="home-service-type"
                            label="Switch to SCANA Energy"
                            defaultChecked={enrollmentState.enrollmentTypeCode === EnrollmentTypeCode.SwitchService}
                            value={EnrollmentTypeCode.SwitchService}
                        />
                        <ErrorField showErrorMessage={errors.enrollmentTypeCode} errorMessage={'Please select type of service.'} />
                    </ServiceTypeInputContainer>
                    {enrollmentTypeCode === EnrollmentTypeCode.SwitchService && <MarketSwitchEstimatedStartDate />}
                    {enrollmentTypeCode === EnrollmentTypeCode.NewService && <NewServiceStartDate />}
                    <StepNav stepFwdButtonText={stepFwdButtonText} styleStepBackAsButton={true} />
                </FormWrapper>
            </FormProvider>
        </>
    )
}

interface StartDateStepProps extends CheckoutStepProps {
    readonly checkIsSelectedOfferExpired: () => Promise<boolean>
}
