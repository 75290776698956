import * as React from 'react'
import { FaPencilAlt } from 'react-icons/fa'

import styled, { css } from 'styled-components'

import { Button, ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { CardContainer } from '@igs-web/common-ui-components/_atoms/card'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { ContainerGrid, commonGridCss } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeVerySmall, lightDark, success, warning } from '@igs-web/common-ui-components/styles/theme'

export const CheckoutGrid = styled(ContainerGrid)`
    grid-column: 1/ -1;
`
export const Checkout2x2CardGrid = styled.div`
    ${commonGridCss};
    grid-template-columns: 1fr 1fr;
    > ${CardContainer} {
        height: auto;
        place-items: start;
    }
`

const layoutWithSidebar = css`
    grid-template-columns: 2fr 3fr;
    grid-template-areas: 'sidebar content' 'footer footer';
`
const layoutNoSidebar = css`
    grid-template-columns: 1fr;
    grid-template-areas: 'content' 'footer';
`
export const CheckoutLayout = styled.div<CheckoutLayoutProps>`
    ${commonGridCss}
    grid-template-rows: 1fr min-content;
    ${({ $showSidebar }) => ($showSidebar ? layoutWithSidebar : layoutNoSidebar)}

    aside {
        grid-area: sidebar;
        display: flex;
        flex-direction: column;
        row-gap: ${Spacing.Large};
    }
    footer {
        padding-top: ${Spacing.ExtraLarge};
        row-gap: ${Spacing.Large};
        display: flex;
        flex-direction: column;
        grid-area: footer;
    }
    * > {
        grid-template-area: content;
    }
    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-areas: 'content' 'footer';
        padding-inline: ${Spacing.Medium};
    }
`
interface CheckoutLayoutProps {
    readonly $showSidebar: boolean
}

export const SuccessHeading = styled(IconHeading)`
    svg {
        color: ${success};
    }
`
export const WarningHeading = styled(IconHeading)`
    svg {
        color: ${warning};
    }
`

export const flexSpread = css`
    display: flex;
    flex-direction: column;
    gap: ${Spacing.Medium};
    @media (min-width: ${Breakpoint.Tablet}) {
        flex-direction: row;
        justify-content: space-between;
        gap: 0;
    }
`

const ButtonHeadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    ${IconHeading} {
        border: none;
        padding: 0;
    }
    padding-bottom: ${Spacing.Large};
`

export const SmallButton = styled(Button)`
    padding-block: ${Spacing.ExtraSmall};
    padding-inline: ${Spacing.Medium};
    letter-spacing: ${Spacing.None};
    height: min-content;
    white-space: nowrap;
    svg {
        font-size: ${fontSizeVerySmall};
        margin-right: ${Spacing.ExtraSmall};
    }
`

export const HeadingWithButton = ({ children, buttonText, hideButton = false, onClick, headingDataTestId, buttonDataTestId }: HeadingWithButtonProps) => (
    <ButtonHeadingContainer>
        <IconHeading data-testid={headingDataTestId}>{children}</IconHeading>
        {!hideButton && (
            <SmallButton buttonStyle={ButtonStyleType.Secondary} onClick={onClick} dataTestId={buttonDataTestId}>
                {buttonText}
            </SmallButton>
        )}
    </ButtonHeadingContainer>
)

interface HeadingWithButtonProps {
    readonly children?: React.ReactNode
    readonly buttonText: React.ReactNode
    readonly hideButton?: boolean
    readonly onClick: () => void
    readonly headingDataTestId?: string
    readonly buttonDataTestId?: string
}

export const EditHeading = ({ children, onClick, hideEdit = false }: EditHeadingProps) => (
    <HeadingWithButton
        buttonText={
            <>
                <FaPencilAlt /> Edit
            </>
        }
        hideButton={hideEdit}
        onClick={onClick}
    >
        {children}
    </HeadingWithButton>
)

interface EditHeadingProps {
    readonly children?: React.ReactNode
    readonly hideEdit?: boolean
    readonly onClick: () => void
}

export const SummarySelector = styled.div`
    padding-top: ${Spacing.Large};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${Breakpoint.Mobile}) {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: ${Spacing.Small};
    }
`

export const BackButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    place-content: end;
    place-items: end;
`

export const AddressSearchResultsWrapper = styled.div`
    display: grid;
    grid-row-gap: ${Spacing.Small};
    > a:hover {
        background: ${lightDark};
    }
`
