import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import { get } from 'lodash-es'
import styled from 'styled-components'

import { FullStory } from '@igs-web/common-models/constants/full-story'
import { CreditCardFields } from '@igs-web/common-models/models/payment-model'
import { zipCodePattern } from '@igs-web/common-ui-components/_atoms/react-hooks-helpers'
import { ElementWithTooltip } from '@igs-web/common-ui-components/_atoms/tooltips/tool-tip'
import { Input, Select, SelectOption } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { commonGridCss } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoIcon } from '@igs-web/common-ui-components/_molecules/info-text'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { fontSizeVerySmall } from '@igs-web/common-ui-components/styles/theme'
import { apiClient } from '@igs-web/common-utilities/api/api-client'
import { rangeInclusive } from '@igs-web/common-utilities/utilities/array-utilities'
import { getCardType, getCardTypeRegex, getSecurityCodeLength, getSecurityCodeRegex } from '@igs-web/common-utilities/utilities/credit-card-utilities'
import { months } from '@igs-web/common-utilities/utilities/date-utilities'

const currentYear = new Date().getFullYear()
const EXPIRATION_YEAR_RANGE = 20
const maxExpirationYear = currentYear + EXPIRATION_YEAR_RANGE

const yearOptions = rangeInclusive(currentYear, maxExpirationYear).map(year => ({
    value: year,
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    text: year.toString().slice(-2),
}))

const monthOptions = months.map(m => m.ordinal)

export const CreditCheckLayout = styled.div`
    ${commonGridCss}
    --cc-grid-template-areas:         'name name name name'
        'number number number type'
        'month month year year'
        'cvv cvv zip zip'
        'tooltip blank blank blank';
    --cc-grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: var(--cc-grid-template-columns);
    grid-template-areas: var(--cc-grid-template-areas);
    @media (max-width: ${Breakpoint.Tablet}) {
        --cc-grid-template-areas: 'name' 'number' 'type' 'month' 'year' 'cvv' 'tooltip' 'zip';
    }

    .area-name {
        grid-area: name;
    }
    .area-number {
        grid-area: number;
    }
    .area-type {
        grid-area: type;
    }
    .area-month {
        grid-area: month;
    }
    .area-year {
        grid-area: year;
    }
    .area-cvv {
        grid-area: cvv;
    }
    .area-zip {
        grid-area: zip;
    }
`

export const CreditCardCaptureForm = ({ formKey = '', idPrefix, payment, isReadonly, paymentSelectorField }: props) => {
    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext()

    const nameField = `${formKey}name`
    const nameFieldErrors = get(errors, `${nameField}.message`)
    const cardNumberField = `${formKey}cardNumber`
    const cardNumberFieldErrors = get(errors, `${cardNumberField}.message`)
    const expirationMonthField = `${formKey}expirationMonth`
    const expirationMonthFieldErrors = get(errors, `${expirationMonthField}.message`)
    const expirationYearField = `${formKey}expirationYear`
    const expirationYearFieldErrors = get(errors, `${expirationYearField}.message`)
    const securityCodeField = `${formKey}securityCode`
    const securityCodeFieldError = get(errors, `${securityCodeField}.message`)
    const cardTypeField = `${formKey}cardType`
    const zipCodeField = `${formKey}zipCode`
    const zipCodeFieldError = get(errors, `${zipCodeField}.message`)

    const paymentSelector = watch(paymentSelectorField)
    React.useEffect(() => {
        setValue(nameField, payment?.name)
        setValue(cardNumberField, payment?.cardNumber)
        setValue(expirationMonthField, payment?.expirationMonth)
        setValue(expirationYearField, payment?.expirationYear)
        setValue(securityCodeField, payment?.securityCode)
        setValue(zipCodeField, payment?.zipCode)
        setValue(cardTypeField, getCardType(payment?.cardNumber ?? ''))
    }, [paymentSelector, watch])

    const cardNumber = watch(cardNumberField)
    const cardType = getCardType(cardNumber ?? '')
    setValue(cardTypeField, cardType)
    const cardNumberRegex: RegExp = cardType ? getCardTypeRegex(cardType) : new RegExp('/*')

    return (
        <CreditCheckLayout>
            <Input
                {...register(`${formKey}name`, { required: 'Required', shouldUnregister: true })}
                dataTestId={`${idPrefix}-credit-card-name`}
                defaultValue={payment?.name ?? ''}
                label="Name as listed on Credit Card"
                autoComplete="cc-name"
                error={nameFieldErrors}
                hideHelperText={!nameFieldErrors}
                readOnly={isReadonly}
                className="area-name"
            />
            <Input
                className={FullStory.Exclude + ' area-number'}
                {...register(cardNumberField, {
                    required: 'Required',
                    pattern: { value: cardNumberRegex, message: 'Card Number is invalid' },
                    shouldUnregister: true,
                })}
                dataTestId={`${idPrefix}-card-number`}
                defaultValue={payment?.cardNumber ?? ''}
                label="Credit Card Number"
                type="tel"
                autoComplete="cc-number"
                error={cardNumberFieldErrors}
                hideHelperText={!cardNumberFieldErrors}
                readOnly={isReadonly}
            />
            <Input
                {...register(cardTypeField, { shouldUnregister: true })}
                dataTestId={`${idPrefix}-card-type`}
                label="Card Type"
                readOnly={true}
                defaultValue={payment?.cardType}
                className="area-type"
                hideHelperText={true}
                tabIndex={-1}
            />
            {isReadonly ? (
                <Input
                    className={FullStory.Exclude + ' area-month'}
                    {...register(expirationMonthField, { shouldUnregister: true })}
                    defaultValue={payment?.expirationMonth ?? ''}
                    label="MM"
                    dataTestId={`${idPrefix}-credit-card-months`}
                    autoComplete="cc-exp-month"
                    hideHelperText={true}
                    readOnly={true}
                />
            ) : (
                <Select
                    className={FullStory.Exclude + ' area-month'}
                    {...register(expirationMonthField, { required: 'Required', shouldUnregister: true })}
                    defaultValue={payment?.expirationMonth ?? ''}
                    label="MM"
                    dataTestId={`${idPrefix}-credit-card-months`}
                    autoComplete="cc-exp-month"
                    error={expirationMonthFieldErrors}
                    hideHelperText={!expirationMonthFieldErrors}
                >
                    {monthOptions.map(month => (
                        <SelectOption dataTestId={`${idPrefix}-credit-card-months-${month}`} key={month} value={month}>
                            {month < 10 ? `0${month}` : month}
                        </SelectOption>
                    ))}
                </Select>
            )}

            {isReadonly ? (
                <Input
                    className={FullStory.Exclude + ' area-year'}
                    {...register(expirationYearField, { shouldUnregister: true })}
                    defaultValue={payment?.expirationYear ?? ''}
                    label="YY"
                    dataTestId={`${idPrefix}-credit-card-year`}
                    autoComplete="cc-exp-year"
                    hideHelperText={true}
                    readOnly={true}
                />
            ) : (
                <Select
                    className={FullStory.Exclude + ' area-year'}
                    {...register(expirationYearField, { required: 'Required', shouldUnregister: true })}
                    defaultValue={payment?.expirationYear ?? ''}
                    label="YY"
                    dataTestId={`${idPrefix}-credit-card-year`}
                    autoComplete="cc-exp-year"
                    error={expirationYearFieldErrors}
                    hideHelperText={!expirationYearFieldErrors}
                >
                    {yearOptions.map(({ value, text }) => (
                        <SelectOption dataTestId={`${idPrefix}-credit-card-year-${value}`} key={value} value={value}>
                            {text}
                        </SelectOption>
                    ))}
                </Select>
            )}
            <Input
                className={FullStory.Exclude + ' area-cvv'}
                {...register(securityCodeField, {
                    required: 'Required',
                    pattern: { value: getSecurityCodeRegex(cardType), message: 'Invalid CCV' },
                    shouldUnregister: true,
                })}
                dataTestId={`${idPrefix}-cc-security-code`}
                defaultValue={payment?.securityCode ?? ''}
                maxLength={getSecurityCodeLength(cardType)}
                label="CVV"
                error={securityCodeFieldError}
                hideHelperText={!securityCodeFieldError}
                autoComplete="cc-csc"
                readOnly={isReadonly}
            />
            <Input
                {...register(zipCodeField, { required: 'Required', pattern: zipCodePattern, shouldUnregister: true })}
                dataTestId={`${idPrefix}-credit-card-zip-code`}
                defaultValue={payment?.zipCode ?? ''}
                label="ZIP Code"
                error={zipCodeFieldError}
                hideHelperText={!zipCodeFieldError}
                autoComplete="cc-postal-code"
                maxLength={5}
                readOnly={isReadonly}
                inputMode="numeric"
                className="area-zip"
            />
            <CvvTooltop />
        </CreditCheckLayout>
    )
}

const StyledElementWithTooltip = styled(ElementWithTooltip)`
    font-size: ${fontSizeVerySmall};
    grid-area: tooltip;
`
const CvvTooltop = () => (
    <StyledElementWithTooltip
        defaultTooltipWidth={400}
        tooltipContents={
            <div>
                <img style={{ width: '100%' }} src={apiClient.getAssetPath('cvv-guide.jpg')} />
            </div>
        }
    >
        <span className="small-text">
            What&apos;s This? <InfoIcon />
        </span>
    </StyledElementWithTooltip>
)

interface props {
    readonly formKey?: string
    readonly idPrefix: string
    readonly payment?: CreditCardFields
    readonly isReadonly: boolean
    readonly paymentSelectorField: string
}
