import { get } from 'lodash-es'
import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import { UtilityBillFields } from '@igs-web/common-models/models/payment-model'
import { UtilityLineOfBusinessModel } from '@igs-web/common-models/models/utility-line-of-business-model'
import { Input, Select, SelectOption } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { billingUtilities } from '@igs-web/common-utilities/utilities/billing-utility'

import { UtilityAccountNumberForm } from '../checkout/forms/utility-account-number-form'

const getUtilitySpecificDisclaimers = (utilityCode: string) => {
    const billingUtility = billingUtilities[utilityCode]
    return billingUtility && billingUtility.disclaimer
}

const getUlobByUtilityCode = (
    ulobs: ReadonlyArray<UtilityLineOfBusinessModel>,
    utilityCode: string,
    lobCode: string,
): UtilityLineOfBusinessModel | undefined => {
    return ulobs.find(ulob => ulob.utilityCode === utilityCode && ulob.lineOfBusinessCode === lobCode)
}
const mapUlobCode = (ulobs: ReadonlyArray<UtilityLineOfBusinessModel>, ulobCode: string): UtilityLineOfBusinessModel | undefined => {
    return ulobs.find(ulob => ulob.utilityLineOfBusinessCode === ulobCode)
}

export const UtilityBillCaptureForm = ({ formKey = '', idPrefix, payment, paymentSelectorField, isReadonly, ulobs = [] }: Props) => {
    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext()

    const [ulob, setUlob] = React.useState<UtilityLineOfBusinessModel>()

    const billingUtilityField = `${formKey}billingUtilityCode`
    const billingUtilityLineOfBusinessCodeField = `${formKey}billingUtilityLineOfBusinessCode`
    const billingUtilityFieldErrors = get(errors, `${billingUtilityField}.message`)
    const billingLineOfBusinessField = `${formKey}billingLineOfBusinessCode`
    const accountNumberField = `${formKey}billingUtilityAccountNumber`

    register(billingLineOfBusinessField)
    register(billingUtilityField)

    const paymentSelector = watch(paymentSelectorField)
    const billingUtilityLineOfBusinessCodeFieldValue = watch(billingUtilityLineOfBusinessCodeField)

    React.useEffect(() => {
        if (payment?.billingUtilityCode) {
            setValue(billingUtilityField, payment?.billingUtilityCode)
            setValue(accountNumberField, payment?.billingUtilityAccountNumber)
            setValue(billingLineOfBusinessField, payment?.billingLineOfBusinessCode)
            setUlob(getUlobByUtilityCode(ulobs, payment?.billingUtilityCode || '', payment?.billingLineOfBusinessCode))
        } else if (ulobs.length === 1) {
            setValue(billingUtilityField, ulobs[0].utilityCode)
            setValue(billingLineOfBusinessField, ulobs[0].lineOfBusinessCode)
            setUlob(mapUlobCode(ulobs, ulobs[0].utilityLineOfBusinessCode))
        } else {
            const mappedUlob = mapUlobCode(ulobs, billingUtilityLineOfBusinessCodeFieldValue)
            setUlob(mappedUlob)
            setValue(billingUtilityField, mappedUlob?.utilityCode)
            setValue(billingLineOfBusinessField, mappedUlob?.lineOfBusinessCode)
        }
    }, [
        paymentSelector,
        billingUtilityLineOfBusinessCodeFieldValue,
        payment?.billingUtilityCode,
        payment?.billingLineOfBusinessCode,
        ulobs,
        setValue,
        billingUtilityField,
        billingLineOfBusinessField,
    ])

    return isReadonly ? (
        <>
            <Input
                {...register(billingUtilityField, { required: 'Required', shouldUnregister: true })}
                dataTestId={`${idPrefix}-utility-selector`}
                error={billingUtilityFieldErrors}
                hideHelperText={!billingUtilityFieldErrors}
                defaultValue={payment?.billingUtilityCode ?? ''}
                label="Utility"
                readOnly={isReadonly}
            />

            {ulob && (
                <div>
                    <Input
                        {...register(accountNumberField, {
                            required: 'Required',
                            shouldUnregister: true,
                        })}
                        dataTestId={`${idPrefix}-accountNumber`}
                        label={ulob?.accountNumberLabel ?? 'Account Number'}
                        readOnly={isReadonly}
                        defaultValue={payment?.billingUtilityAccountNumber}
                    />
                    <div>{getUtilitySpecificDisclaimers(ulob.utilityCode)}</div>
                </div>
            )}
        </>
    ) : (
        <>
            <Select
                {...register(billingUtilityLineOfBusinessCodeField, { required: 'Required', shouldUnregister: true })}
                dataTestId={`${idPrefix}-payment-type-selector`}
                error={billingUtilityFieldErrors}
                hideHelperText={!billingUtilityFieldErrors}
                defaultValue={payment?.billingUtilityCode ?? ''}
                allowClear={false}
                label="Utility"
            >
                {ulobs.map(utillob => (
                    <SelectOption
                        key={utillob.utilityLineOfBusinessCode}
                        dataTestId={`${idPrefix}-payment-type-${utillob.utilityCode}`}
                        value={utillob.utilityLineOfBusinessCode}
                    >
                        {utillob.utility}
                    </SelectOption>
                ))}
            </Select>
            {ulob && (
                <div>
                    <UtilityAccountNumberForm
                        ulob={ulob}
                        idPrefix={idPrefix}
                        formKey={`${formKey}.billingUtility`}
                        accountNumber={payment?.billingUtilityAccountNumber}
                    />
                    <div>{getUtilitySpecificDisclaimers(ulob.utilityCode)}</div>
                </div>
            )}
        </>
    )
}

interface Props {
    readonly formKey?: string
    readonly idPrefix: string
    readonly payment?: UtilityBillFields
    readonly isReadonly: boolean
    readonly paymentSelectorField: string
    readonly ulobs?: ReadonlyArray<UtilityLineOfBusinessModel>
    readonly showAchZip?: boolean
}
